import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { OauthFetch, TikTokAccountSetParams } from '~/types/social-account'

export const useStoreTiktok = defineStore('accountTiktok', () => {
  const queryClient = useQueryClient()

  const fetchTiktokOauth = async (companyId: number) => {
    return (await useNuxtApp().$api('/api/creator/tiktok-accounts/oauth/', {
      params: {
        company_id: companyId
      }
    })) as OauthFetch
  }

  const setTikTokAccount = () => {
    return useMutation({
      mutationFn: async (form: TikTokAccountSetParams) => {
        await useNuxtApp().$api(`/api/creator/tiktok-accounts/`, {
          method: 'POST',
          body: {
            code: form.code,
            user_tiktok_account: [form.creatorId]
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['fetchCreator'] })
      }
    })
  }

  const clearTikTokAccount = async (accountId: number) => {
    return await useNuxtApp().$api(`/api/creator/tiktok-accounts/${accountId}/`, {
      method: 'DELETE'
    })
  }

  return { fetchTiktokOauth, setTikTokAccount, clearTikTokAccount }
})
